import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];

  initialize() {
    window.console.log('Sortable Controller Initialized!');
  }

  connect() {
    // Get perPage from dataset or default to all items if not paginated
    const perPage = parseInt(this.element.dataset.sortablePerPageValue, 10) || this.itemTargets.length;
    const currentPage = this.getCurrentPage();
    const pageOffset = (currentPage - 1) * perPage;

    $(this.element).sortable({
      handle: '[name=sort_handle]',
      tolerance: 'pointer',
      items: '> [data-target="sortable.item"]',
      cursor: 'move',
      stop: () => {
        $.each(this.itemTargets, (i, item) => {
          const { url, model } = $(item).data();
          const globalPosition = pageOffset + i + 1;

          const data = {};
          data[model] = { position: globalPosition };

          $.ajax({
            method: 'PATCH',
            url: `${url}?do_not_update_ui=true`,
            data,
            dataType: 'script',
            headers: { 'X-CSRF-Token': Rails.csrfToken() },
          });
        });
      },
    });
  }

  getCurrentPage() {
    const urlParams = new URLSearchParams(window.location.search);
    return parseInt(urlParams.get('page')) || 1; // Defaults to page 1 if not specified
  }
}
