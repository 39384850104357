import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'predicateSelect',
    'fieldSelect',
    'valueField',
    'visibleValueField',
    'newTermFields',
    'existingTermFields',
    'existingField',
    'existingPredicate',
    'fieldValuePredicateSelect',
    'fieldValueInput',
    'existingValueFields',
    'existingValue',
    'existingFieldValue',
    'existingFieldValuePredicate',
    'destroyTerm',
    'fieldValueFieldsSelect',
    'fieldValueFieldsInput',
    'displayValue'
  ];

  initialize() {
    window.console.log('ResourceQueryTermForm Controller Initialized!');
  }

  connect() {
    this.render();
    $(this.existingFieldTarget).text(this.$selectedField.text());
    this.updateExisting();
  }

  render(event) {
    this.renderPredicateField();
    this.renderVisibleValueField();
    this.renderFieldValueFields();

    if (event && event.type === 'change') {
      if (this.$visibleValueField.prop('id') === 'single_value_select') {
        this.$valueField.val(this.$visibleValueField.val());
      } else {
        this.$valueField.val('');
      }
    }
  }

  renderPredicateField() {
    const currentOptionValue = this.$predicateSelect.val();

    this.$predicateSelect.val('').find('option').hide().removeClass('visible');

    this.$predicateSelect.find('option').each((i, option) => {
      const $option = $(option);
      const types = $option.data('types').split(',');

      if (types.includes(this.fieldType)) {
        $option.show().addClass('visible');

        if (currentOptionValue === $option.val()) {
          $option.prop('selected', true);
        }
      }
    });

    if (this.$predicateSelect.find('option:selected').length === 0) {
      this.$predicateSelect.find('option.visible').first().prop('selected', true);
    }
  }

  renderVisibleValueField() {
    const $valueFields = $(this.visibleValueFieldTargets);

    $valueFields.closest('[name=input_wrapper]').hide();

    $valueFields.each((i, field) => {
      const $field = $(field);
      const types = $field.data('types').split(',');

      if (types.includes(this.fieldType)) {
        $field.closest('[name=input_wrapper]').show();

        if (this.fieldOptions) {
          this.updateValueSelect($field);
        }
      }
    });
  }

  renderFieldValueFields() {
    if (this.hasFieldValue) {
      this.$fieldValueFieldsSelect.show(100);
      this.$fieldValueFieldsInput.show(100);
    } else {
      this.$fieldValueFieldsSelect.hide();
      this.$fieldValueFieldsInput.hide();
    }
  }

  updateValueSelect($field) {
    $field.find('option').detach();

    $(this.fieldOptions).each((i, option) => {
      $field.append(`<option value="${option[1]}">${option[0]}</option>`);
    });
  }

  updateValue(event) {
    const $valueField = $(event.target);
    this._updateValue($valueField);
  }

  _updateValue($valueField) {
    let value = $valueField.val();

    if (value.length === 0) { return; }

    if ($valueField[0].id === 'multi_value_select') {
      value = `[${value}]`;
    }

    this.$valueField.val(value);
    $(this.existingValueTarget).text(`"${value}"`);
  }

  updateExisting() {
    $(this.existingFieldTarget).text(this.$selectedField.text());
    $(this.existingPredicateTarget).text(this.$selectedPredicate.text());
    $(this.existingFieldValuePredicateTarget).text(this.$selectedFieldValuePredicate.text());
    $(this.existingFieldValueTarget).text(this.$fieldValueInput.val());
    if (this.displayValueTarget.textContent.length) {
      $(this.existingValueTarget).text(`"${this.displayValueTarget.textContent}"`);
    } else {
      $(this.existingValueTarget).text(`"${this.$valueField.val()}"`);
    }

  }

  removeTerm(event) {
    event.preventDefault();
    $(event.target).closest('.fields').hide();
    $(this.visibleValueFieldTargets).detach();
    $(this.destroyTermTarget).prop('checked', true);
    $('h3 .fa-spin').removeClass('hidden').show();
    this.preview();
    this.showAddTermLink();
    this.enableForm();
  }

  addTerm(event) {
    event.preventDefault();

    // if (event.type === 'keyup' && event.key !== 'Enter') { return; }
    if (event.type === 'keyup') { return; }
    // The keyup event is used to capture the return key when pressed on a field.
    // However, becuase the value_field gets set after value is set, this does
    // not currently work for multi_value_select and regular text value, unless
    // a value is chosen.

    if (this.value === '') {
      // If no value has been set.
      this.$visibleValueField.focus();
      window.toastr.error('You must provide a Value');
    } else {
      $('h3 .fa-spin').removeClass('hidden').show();
      $(this.newTermFieldsTarget).hide();
      this.updateExisting();
      $(this.existingTermFieldsTarget).show();
      if (this.hasFieldValue) { this.$existingValueFields.show(); }
      this.showAddTermLink();
      this.enableForm();
      this.preview();
    }
  }

  preview() {
    $.ajax({
      method: 'GET',
      url: `${this.$form.attr('action')}/preview`,
      data: this.$form.serialize(),
      dataType: 'script',
    });
  }

  showAddTermLink() {
    // This is outside of this controller.
    $('.add_term_link').show();
  }

  enableForm() {
    this.$form.find('.resource_query_controls input[type=submit]').prop('disabled', false);
  }

  get fieldType() {
    return this.$selectedField.data('type');
  }

  get fieldOptions() {
    const options = this.$selectedField.data('options');

    if (options) {
      return options.split(';').map((option) => {
        const splitOption = option.split(',');
        return [splitOption[0], splitOption[1]];
      });
    }

    return null;
  }

  get $fieldSelect() {
    return $(this.fieldSelectTarget);
  }

  get $selectedField() {
    return this.$fieldSelect.find('option:selected');
  }

  get $predicateSelect() {
    return $(this.predicateSelectTarget);
  }

  get $fieldValueFieldsSelect() {
    return $(this.fieldValueFieldsSelectTarget);
  }

  get $fieldValueFieldsInput() {
    return $(this.fieldValueFieldsInputTarget);
  }

  get hasFieldValue() {
    return this.$selectedField.data('has-field-value');
  }

  get $existingValueFields() {
    return $(this.existingValueFieldsTarget);
  }

  get $fieldValuePredicateSelect() {
    return $(this.fieldValuePredicateSelectTarget);
  }

  get $valueField() {
    return $(this.valueFieldTarget);
  }

  get value() {
    return this.$valueField.val();
  }

  get $fieldValueInput() {
    return $(this.fieldValueInputTarget);
  }

  get $selectedPredicate() {
    return this.$predicateSelect.find('option:selected');
  }

  get $selectedFieldValuePredicate() {
    return this.$fieldValuePredicateSelect.find('option:selected');
  }

  get $visibleValueField() {
    // For some reasong, fiding the :visible of Stimulus targets isn't working.
    return $('[data-target="resource-query-term-form.visibleValueField"]:visible');
  }

  get $form() {
    return $(this.element).closest('form');
  }
}
