import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['list']

  connect() {
    this.index();
    this.handleSavedSearchCreated = this.handleSavedSearchCreated.bind(this);
    window.addEventListener('saved_search_created', this.handleSavedSearchCreated);
  }

  disconnect() {
    window.removeEventListener('saved_search_created', this.handleSavedSearchCreated);
  }

  handleSavedSearchCreated(event) {
    this.index();
  }

  index() {
    const url = this.data.get('url');
    const page_name = this.data.get('page-name');
    const activeItem = this.data.get('active');

    fetch(`${url}/?page_name=${encodeURIComponent(page_name)}`)
      .then(response => response.json())
      .then((data) => {
        this.listTarget.innerHTML = data.map(item => `
          <div 
            data-controller="saved-search"
            data-target="saved-search.item"
            class="saved-search droplist-link list-item"
            data-saved-search-id="${item.id}"
            data-saved-search-url="${url}"
            data-saved-search-name="${item.name}"
            data-status="${activeItem === item.id.toString() ? 'active' : ''}">
            <div class="saved-search-link" data-target="saved-search.link">
              ${item.link}
            </div>
            <div class="saved-search-form">
              <span role="textbox" data-target="saved-search.input" class="input form-input block text-sm py-1 px-2 mr-2" contenteditable>${item.name}</span>
              <div data-action="click->saved-search#edit" class="edit text-blue-700"><i class="fas fa-edit mx-2"></i></div>
              <div data-action="click->saved-search#update" class="update update nline-flex justify-center rounded-md px-2 py-1 bg-blue-100 text-xs leading-5 font-semibold text-blue-700 cursor-pointerr">Save</div>
              <div data-action="click->saved-search#destroy" class="destroy text-red-500"><i class="fas fa-trash mx-2"></i></div>
            </div>
          </div>
        `).join('');
      });
  }
}
